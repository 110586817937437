import moment from "moment";

export const getTotalDeliveryCost = ({cost_details, delivery_cost_on, cost_on_issuer}: any) => {


    cost_on_issuer += (cost_details.parcel_surcharges || 0) + (cost_details.delivery_surcharges || 0)

    const cost = cost_on_issuer;
    switch (delivery_cost_on) {
        case 'business':
            cost_on_issuer = 0;
            break;
        case 'split':
            cost_on_issuer = cost_on_issuer / 2;
            break;
    }
    return {cost, cost_on_issuer};
};

export const removeFullScreenClasses = () => {
    const bodyClassList = document.getElementsByTagName('body')[0].classList;
    bodyClassList.remove("full-screen");
    bodyClassList.remove("medium-height");
};


export const getDeliveryCostById = (id: number, options: any) => {
    if (id && options && options.length > 0) {
        const match = options.filter((obj: any) => obj.id === id);
        return match.length ? match[0] : options[0];
    }
    return options[0];
}

export const arabicNumberToEnglish = (input: any) => {
    if (typeof input !== 'string') {
        return input;
    }

    const arabicMap: any = {
        '٠': 0,
        '١': 1,
        '٢': 2,
        '٣': 3,
        '٤': 4,
        '٥': 5,
        '٦': 6,
        '٧': 7,
        '٨': 8,
        '٩': 9
    };
    return Number(input.split('').map((n: any) => arabicMap[n] !== undefined ? arabicMap[n] : n).join(''));
}

export const getBreakpoint = () => {
    const width = window.innerWidth;
    if (width < 576) {
        return 'xs';
    } else if (width >= 576 && width < 768) {
        return 'sm';
    } else if (width >= 768 && width < 992) {
        return 'md';
    } else if (width >= 992 && width < 1200) {
        return 'lg';
    } else if (width >= 1200 && width < 1600) {
        return 'xl';
    } else {
        return 'xxl';
    }
};

const minutes = [
    {
        value: 0,
        label: '00'
    },
    {
        value: 15,
        label: '15'
    },
    {
        value: 30,
        label: '30'
    },
    {
        value: 45,
        label: '45'
    }
]
export const toDurationOptions = [
    {
        value: 5,
        label: '5',
        children: [
            ...minutes
        ],
    },
    {
        value: 6,
        label: '6',
        children: [
            ...minutes
        ],
    },
    {
        value: 7,
        label: '7',
        children: [
            ...minutes
        ],
    },
    {
        value: 8,
        label: '8',
        children: [
            minutes[0]
        ],
    }
];

export const fract = (n: number) => {
    return String(n).split('.')[1]?.substr(0, 2) || '00';
}

export const locationPointPattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
export const locationPointOrMapPattern = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
export const phoneNumberPattern = /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
export const moilePattern = /^(\+970|00970|970|009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
// export const moilePattern = /[0-9]+/;
export const globalPhoneNumberPattern = /^(\+|00)*[0-9]{10,12}$/;
export const emailOrSaudiMobile = /^(Adminstrator)|(administrator)|([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)|((966|\+966|00966)*(5|05)[0-9]{8})$/;
export const emailOrGlobalMobile = /^(Adminstrator)|(administrator)|([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)|(\+|00)*[0-9]{10,12}$/;


export const DEFAULT_START_DATE = moment().subtract(7, 'days').format('YYYY-MM-DD');
export const DEFAULT_END_DATE = moment().format('YYYY-MM-DD');
//  for concat the url based on the country id
export const getRoutePrefix=(serviseURl:string)=>{

    return `${serviseURl}`;
}
export const getServiceRoutePrefix = (link:string, service:string) =>{
    return `/${link}`;
        
    
}