const mainState = {
  loading: false,
  error: false,
  isLoggedUser: false,
  message: "",
  user: null,
  isFullHeightPage: false,
  insurrances:[],
  garagesNetworks: [],
};

export default mainState;
