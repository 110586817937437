export const API_SUCCESS = 'API_SUCCESS';

export const PRICEOFFERLOADING = 'PRICEOFFERLOADING';
export const PRICEOFFERLOADED = 'PRICEOFFERLOADED';

export const COMMITMENTSENDING = 'COMMITMENTSENDING';
export const COMMITMENTSENT = 'COMMITMENTSENT';

export const PURCHASEORDERSLOADING = 'PURCHASEORDERSLOADING';
export const PURCHASEORDERSLOADED = 'PURCHASEORDERSLOADED';

export const PURCHASEORDERDELETING = 'PURCHASEORDERDELETING';
export const PURCHASEORDERDELETED = 'PURCHASEORDERDELETED';

export const CLAIMLOADING = 'CLAIMLOADING';
export const CLAIMLOADED = 'CLAIMLOADED';

export const FIXINGFEESLOADING = 'FIXINGFEESLOADING';
export const FIXINGFEESLOADED = 'FIXINGFEESLOADED';

export const FIXINGFEESSAVING = 'FIXINGFEESSAVING';
export const FIXINGFEESSAVED = 'FIXINGFEESSAVED';

export const FIXINGFEESLOGSLOADING = 'FIXINGFEESLOGSLOADING';
export const FIXINGFEESLOGSLOADED = 'FIXINGFEESLOGSLOADED';

export const GARAGEREPORTIMESAVING = 'GARAGEREPORTIMESAVING';
export const GARAGEREPORTIMESAVED = 'GARAGEREPORTIMESAVED';

export const PARTSPRICESAVING = 'PARTSPRICESAVING';
export const PARTSPRICESAVED = 'PARTSPRICESAVED';

export const REPORTDELETING = 'REPORTDELETING';
export const REPORTDELETED = 'REPORTDELETED';

export const PURCHASEORDERSENDING = 'PURCHASEORDERSENDING';
export const PURCHASEORDERSENT = 'PURCHASEORDERSENT';

export const CLAIMINFOSAVING = 'CLAIMINFOSAVING';
export const CLAIMINFOSAVED = 'CLAIMINFOSAVED';

export const LOADING = 'LOADING';

export const GARAGEPRICEOFFERLOADED = 'GARAGEPRICEOFFERLOADED';
export const SAVEPARTSPRICELOADED = 'SAVEPARTSPRICELOADED';
export const GARAGEREPORTSLOADED = 'GARAGEREPORTSLOADED';
export const GARAGEREPORTDELETED = 'GARAGEREPORTDELETED';
export const EVALUATINGSELLER = 'EVALUATINGSELLER';
export const SELLEREVALUATED = 'SELLEREVALUATED';

export const SELLEREVALUATIONSLOADING = 'SELLEREVALUATIONSLOADING';
export const SELLEREVALUATIONSLOADED = 'SELLEREVALUATIONSLOADED';
export const SENDEMAILLOADING = 'SENDEMAILLOADING';
export const SENDEMAILLOADED = 'SENDEMAILLOADED';

export const FIXINGFEESCOMMITMENTSENDING = 'FIXINGFEESCOMMITMENTSENDING';
export const FIXINGFEESCOMMITMENTSENT = 'FIXINGFEESCOMMITMENTSENT';

export const SCRAPPEDVEHICLEINFOSAVING = 'SCRAPPEDVEHICLEINFOSAVING';
export const SCRAPPEDVEHICLEINFOSAVED = 'SCRAPPEDVEHICLEINFOSAVED';

export const SCRAPPEDVEHICLEOFFERSLOADING = 'SCRAPPEDVEHICLEOFFERSLOADING';
export const SCRAPPEDVEHICLEOFFERSLOADED = 'SCRAPPEDVEHICLEOFFERSLOADED';

export const GENERATEQUTITTANCELOADING = 'GENERATEQUTITTANCELOADING';
export const GENERATEQUTITTANCELOADED = 'GENERATEQUTITTANCELOADED';

export const QUTITTANCEDELETING = 'QUTITTANCEDELETING';
export const QUTITTANCEDELETED = 'QUTITTANCEDELETED';

