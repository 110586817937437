import {LOADING, START, API_SUCCESS, API_FAILURE, LOGGING, LOGIN_FAILED, API_DONE, TOKENREFRESHED} from './Auth.actions';

import {default as initialState} from './Auth.state';

export const AuthReducer = (state = initialState, action: any) => {
    let newState = state;
    const removeErrorMessage = {message: '', error: false};
    switch (action.type) {
        case LOADING:
            newState = {...state, loading: true, ...removeErrorMessage};
            break;
        case API_DONE:
            newState = {...state, loading: false};
            break;
        case API_SUCCESS:
            newState = {...state, loading: false, ...removeErrorMessage};
            break;
        case API_FAILURE:
            newState = {...state, loading: false, error: true, message: action.message};
            break;
        case LOGGING:
            newState = {...state, loading: true};
            break;
        case LOGIN_FAILED:
            newState = {...state, loading: false, message: action.message};
            break;
        case START:
            newState={...state,loading:false};
        case TOKENREFRESHED:
            newState={...state, loading:false};
    }

    return newState
};
