import {
  LOADING,
  API_SUCCESS,
  API_FAILURE,
  USER_DATA_LOADED,
  INSURRANCE_COMPANIES_LOADING,
  INSURRANCE_COMPANIES_LOADED,
} from "./Main.actions";

import { default as initialState } from "./Main.state";

export const MainReducer = (state = initialState, action: any) => {
  let newState = state;
  const removeErrorMessage = { message: "", error: false };
  switch (action.type) {
    case LOADING:
      newState = { ...state, loading: true, ...removeErrorMessage };
      break;
    case API_SUCCESS:
      newState = { ...state, loading: false, ...removeErrorMessage };
      break;
    case API_FAILURE:
      newState = { ...state, loading: false, error: true,  message: action.message,  };
      break;
    case USER_DATA_LOADED: 
    newState = { ...state, loading: false, user: action.payload,  };
      break;
    case INSURRANCE_COMPANIES_LOADING: newState = { ...state, loading: true, ...removeErrorMessage };
      break;    
    case INSURRANCE_COMPANIES_LOADED: newState = { ...state, loading: false, insurrances: action.payload, };
      break;
  }

  return newState;
};
