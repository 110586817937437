import {Action, Reducer} from 'redux';
import { USER_SEARCHING, USER_SEARCHED,MODELSLOADED, MODELSLOADING, SEARCHING, SEARCHED, CLAIMSPAGENUMBERLOADED, PRICE_OFFER_ADDED, PRICE_OFFER_ADDING } from './AddPriceFromClient.actions';



import {initialState} from './AddPriceFromClient.state';

export const AddPriceFromClientReducer: Reducer<any, Action> = (state = initialState, action: any ) => {
    let newState = state;
    switch (action.type) {
        case MODELSLOADING : newState = {...newState, loading:true};
        break;

        case MODELSLOADED : newState = {...newState, loading:false, vehicleModels: action.payload}
        break;

        case SEARCHING : newState = {...newState, searchLoading:true}
        break;

        case SEARCHED : newState = {...newState, searchLoading: false, searchResult: action.payload}
        break;

        case CLAIMSPAGENUMBERLOADED: newState = {...newState, claimsLoading:false, claims_pages:action.payload};
        break;

        case USER_SEARCHING : newState = {...newState, searchLoading: false,}
        break;

        case USER_SEARCHED: newState = {...newState, claimsLoading:false, users:action.payload};
        break;

        case PRICE_OFFER_ADDING: newState = {...newState, addPriceOfferLoading: true};
        break;
        case PRICE_OFFER_ADDED: newState = {...newState, addPriceOfferLoading: false};
        break;
    }
    return newState;
};
