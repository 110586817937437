import { Action, Reducer } from 'redux';
import { DATA_LOADING, DATA_LOADED, CLAIMSLISTSLOADED, REPORT_LOADING, REPORT_LOADED, SELLERSLOADED, SELLERSLOADING } from './Reports.action';
import { initialState } from './Reports.state';

export const ReportsReducer: Reducer<any, Action> = (
    state = initialState,
    action: any
) => {
    let newState = state
    switch (action?.type) {
        case DATA_LOADING:
            newState = { ...state, getDataLoading: true };
            break;
        case DATA_LOADED:
            newState = { ...state, getDataLoading: false, result: action?.payload };
            break;
        case CLAIMSLISTSLOADED:
            newState = { ...newState, loading: false, lists: action?.payload }; break;
        case REPORT_LOADING:
            newState = { ...newState, reportGenerating: true}; break;
        case REPORT_LOADED:
            newState = { ...newState, reportGenerating: false}; break;
        case SELLERSLOADING:
            newState = { ...newState, sellersLoading: true}; break;
        case SELLERSLOADED:
            newState = { ...newState, sellersLoading: false, sellersList: action?.payload}; break;
    }
    return newState;
};
