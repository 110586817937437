import {Action, Reducer} from 'redux';
import { AVATARUPLOADED, AVATARUPLOADING, LOADING, USER_DATA_LOADED, USER_DATA_UPDATED } from './EditProfile.actions';



import {initialState} from './EditProfile.state';

export const EditProfileReducer: Reducer<any, Action> = ( state = initialState, action: any ) => {
    let newState = state;
    const removeErrorMessage = { message: "", error: false };

    switch (action.type) {
        case LOADING:
            newState = { ...state, loading: true, ...removeErrorMessage };
            break;
        case USER_DATA_LOADED: 
            newState = { ...state, loading: false, user: action.payload,  };
              break;
        case USER_DATA_UPDATED: 
            newState = { ...state, loading: false};
        break;
        case AVATARUPLOADING: 
            newState = { ...state, uploading: true};
        break;
        case AVATARUPLOADED: 
            newState = { ...state, uploading: false};
        break;
    }
    return newState;
};
