import React, { useEffect } from 'react';
import './App.less';
import { BrowserRouter } from "react-router-dom";
import {Provider} from 'react-redux';
import { ConfigProvider } from 'antd';
import RouterGuard from './Routes/RouteGuard';
import configureAppStore from '../configureStore';
import arEG from 'antd/es/locale/ar_EG';
import enUS from 'antd/es/locale/en_US';
import {useTranslation} from "react-i18next";
import { goToLink } from './services/authService';
import i18n from '../i18n';

const store = configureAppStore();

function App() {
    const { i18n} = useTranslation();
    const defaultLocale = (i18n.dir() === 'rtl' ? arEG : enUS);
    document.body.dir = i18n.dir();

    // const handleMountRoute = () => {
    //     goToLink('/');  
    // }
    // useEffect(() => {
    //     const CurrentLang: string = localStorage.getItem('i18nextLng') ?? 'ar';
    //     handleMountRoute()
    //     i18n.changeLanguage(CurrentLang);
    // }, [i18n.dir()]);

    return (
        <Provider store={store}>
            <BrowserRouter>
                <ConfigProvider locale={defaultLocale}>
                    <RouterGuard/>
                 </ConfigProvider>
            </BrowserRouter>
        </Provider>
    );
}


export default App;
