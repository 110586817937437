export const API_SUCCESS = 'API_SUCCESS';
export const LOADING = 'LOADING';
export const STATSLOADED = 'STATSLOADED';
export const CLAIMSLOADING = 'CLAIMSLOADING';
export const CLAIMSLOADED = 'CLAIMSLOADED';
export const CLAIMSPAGENUMBERLOADED = 'CLAIMSPAGENUMBERLOADED';
export const EXTERNALCLAIMLOADING = 'EXTERNALCLAIMLOADING'
export const EXTERNALCLAIMLOADED = 'EXTERNALCLAIMLOADED'
export const FINANCIALCLAIMLOADING = 'FINANCIALCLAIMLOADING'
export const FINANCIALCLAIMLOADED = 'FINANCIALCLAIMLOADED'
export const STATUSUPDATING = 'STATUSUPDATING';
export const STATUSUPDATED = 'STATUSUPDATED';
export const CLAIMSLISTSLOADED = "CLAIMSLISTSLOADED";
export const CLAIMSLISTSLOADING = "CLAIMSLISTSLOADING";
export const MODELSLOADING = "MODELSLOADING";
export const MODELSLOADED = "MODELSLOADED";