import {Action, Reducer} from 'redux';

import { EXTERNALINSURANCESLOADING,EXTERNALINSURANCESLOADED,BRANCHESLOADING,BRANCHESLOADED, STATUSUPDATING,STATUSUPDATED, CLAIMNEWTENDERPERIODLOADING,CLAIMNEWTENDERPERIODLOADED, CLAIMPARTSLOADED, CLAIMSLISTSLOADED } from './OrderOptions.actions'

import {initialState} from './OrderOptions.state';

export const OrderOptionsReducer: Reducer<any, Action> = (
    state = initialState,
    action: any
) => {
    let newState = state
    switch (action.type) {
        case CLAIMNEWTENDERPERIODLOADING: newState={...newState,loading:true}; break;
        case CLAIMNEWTENDERPERIODLOADED: newState={...newState,loading:false}; break;
        case STATUSUPDATING: newState={...newState,loading:true}; break;
        case STATUSUPDATED: newState={...newState,loading:false}; break;
        case BRANCHESLOADING: newState={...newState,loading:true}; break;
        case BRANCHESLOADED: newState={...newState,loading:false, branches:action.payload}; break;
        case CLAIMSLISTSLOADED :  newState={...newState,loading:false, lists:action.payload}; break;
        case CLAIMPARTSLOADED: newState={...newState, loading:false, claimParts: action.payload}; break;
        case EXTERNALINSURANCESLOADING :  newState={...newState,loading:true, lists:action.payload}; break;
        case EXTERNALINSURANCESLOADED: newState={...newState, loading:false, externalInsurers: action.payload}; break;
    }
    return newState;
};
