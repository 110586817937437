export const CLAIMNEWTENDERPERIODLOADING = 'CLAIMNEWTENDERPERIODLOADING';
export const CLAIMNEWTENDERPERIODLOADED = 'CLAIMNEWTENDERPERIODLOADED';

export const STATUSUPDATING = 'STATUSUPDATING';
export const STATUSUPDATED = 'STATUSUPDATED';

export const BRANCHESLOADING = 'BRANCHESLOADING';
export const BRANCHESLOADED = 'BRANCHESLOADED';

export const EXTERNALINSURANCESLOADING = 'EXTERNALINSURANCESLOADING';
export const EXTERNALINSURANCESLOADED = 'EXTERNALINSURANCESLOADED';

export const CLAIMSLISTSLOADED = 'CLAIMSLISTSLOADED';
export const CLAIMPARTSLOADING = 'CLAIMPARTSLOADING';
export const CLAIMPARTSLOADED = 'CLAIMPARTSLOADED';
