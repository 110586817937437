import jwt_decode from "jwt-decode";
import {history} from 'react-router-guard';
import { getServiceRoutePrefix } from "./helpers";
export const setUser = (data: any) => {
    localStorage.setItem('profile',data)
    localStorage.setItem('token', data?.token);
    localStorage.setItem('user', data?.user);
    localStorage.setItem('expires', data?.expires);

};

export const getProfile: any = () => {

    const profile = localStorage.getItem('userDetails');

    return profile
};

export const getUser = () => {

    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (!user) {
        return {};
    }
    return {user: user, token:token}
};

export const removeUser = () => {
    localStorage.clear()
};

export const is = (role: any) => {
    const token: string = localStorage.getItem('token') || '';
    if (!token) {
        return false;
    }
    let decoded: any ;
    try {
        decoded = jwt_decode(token);
    } catch (error) {
        decoded=false
    }
    return decoded?.role === role;
};

export const getToken = () => {
    return localStorage.getItem('token') || '';
};

// const _getTokenData = () => {
//     const token: string = localStorage.getItem('token') || '';
//     let final_token:any;
//     if (!token) {
//         return {};
//     }
//     try {
//         final_token= jwt_decode(token);
//     } catch (error) {
//         final_token = false;
//     }
//     return final_token;
// };

export const isValidToken = () => {
    const expires = localStorage.getItem('expires') || '';

    return new Date(expires) > new Date();
};

export const goToLink = (link: string,newWindow?: boolean ) => {
    if (link === undefined) {
        return;
    }
//    alert(link)
    history.push(link);
};
