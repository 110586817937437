import {Action, Reducer} from 'redux';
import { PRICEOFFERLOADED, PRICEOFFERLOADING, PURCHASEORDERSLOADING, PURCHASEORDERSLOADED, CLAIMLOADING, CLAIMLOADED, FIXINGFEESLOADED, FIXINGFEESLOADING, FIXINGFEESSAVING, FIXINGFEESSAVED, FIXINGFEESLOGSLOADING, FIXINGFEESLOGSLOADED, GARAGEREPORTIMESAVED, GARAGEREPORTIMESAVING, PARTSPRICESAVED, PARTSPRICESAVING, REPORTDELETED, REPORTDELETING, EVALUATINGSELLER, SELLEREVALUATED, SELLEREVALUATIONSLOADED, SELLEREVALUATIONSLOADING, SENDEMAILLOADED, SENDEMAILLOADING, FIXINGFEESCOMMITMENTSENDING, FIXINGFEESCOMMITMENTSENT, LOADING, GARAGEPRICEOFFERLOADED, SAVEPARTSPRICELOADED, GARAGEREPORTSLOADED, GARAGEREPORTDELETED, CLAIMINFOSAVED, CLAIMINFOSAVING, SCRAPPEDVEHICLEOFFERSLOADED, SCRAPPEDVEHICLEOFFERSLOADING, SCRAPPEDVEHICLEINFOSAVED, SCRAPPEDVEHICLEINFOSAVING, GENERATEQUTITTANCELOADED, GENERATEQUTITTANCELOADING, QUTITTANCEDELETING } from './LossAdjusting.action';
import {initialState} from './LossAdjusting.state';

export const LossAdjustingReducer: Reducer<any, Action> = ( state = initialState, action: any) => {
    let newState = state
    switch (action.type) {
        case PRICEOFFERLOADING:  newState={...newState, priceOffersLoading:true}; break;
        case PRICEOFFERLOADED:  newState={...newState, priceOffersLoading:false, priceOffers:action.payload}; break;
        case PURCHASEORDERSLOADING: newState={...newState, purchaseOrdersLoading: true}; break;
        case PURCHASEORDERSLOADED: newState={...newState, purchaseOrdersLoading: false, purchaseOrders: action.payload}; break;
        case CLAIMLOADING: newState={...newState, claimLoading: true}; break;
        case CLAIMLOADED: newState={...newState, claimLoading: false, claim: action.payload}; break;
        case CLAIMINFOSAVING: newState={...newState, claimSaving: true}; break;
        case CLAIMINFOSAVED: newState={...newState, claimSaving: false}; break;
        case FIXINGFEESLOADING: newState={...newState, fixingFeesLoading: true}; break;
        case FIXINGFEESLOADED: newState={...newState, fixingFeesLoading: false, fixingFees: action.payload}; break;
        case FIXINGFEESSAVING: newState={...newState, fixingFeesSaving: true}; break;
        case FIXINGFEESSAVED: newState={...newState, fixingFeesSaving: false, fixingFees: action.payload}; break;
        case FIXINGFEESLOGSLOADING: newState={...newState, fixingFeesLogsLoading: true}; break;
        case FIXINGFEESLOGSLOADED: newState={...newState, fixingFeesLogsLoading: false, fixingFeesLogs: action.payload}; break;
        case PARTSPRICESAVING: newState={...newState, partsPriceSaving: true}; break;
        case PARTSPRICESAVED: newState={...newState, partsPriceSaving: false}; break;
        case REPORTDELETING: newState={...newState, reportDeleting: true}; break;
        case REPORTDELETED: newState={...newState, reportDeleting: false}; break;
        case SELLEREVALUATIONSLOADING: newState={...newState, sellerEvaluationsLoading: false}; break;
        case SELLEREVALUATIONSLOADED: newState={...newState, sellerEvaluationsLoading: false}; break;
        case SENDEMAILLOADING: newState={...newState, reportDeleting: true}; break;
        case SENDEMAILLOADED: newState={...newState, reportDeleting: true}; break;
        case FIXINGFEESCOMMITMENTSENDING: newState={...newState, finalCommitmentSending: true}; break;
        case FIXINGFEESCOMMITMENTSENT: newState={...newState, finalCommitmentSent: true}; break;
        case SCRAPPEDVEHICLEINFOSAVING: newState={...newState, scrappedVehicleInfoSaving: true}; break;
        case SCRAPPEDVEHICLEINFOSAVED: newState={...newState, scrappedVehicleInfoSaving: false}; break;
        case SCRAPPEDVEHICLEOFFERSLOADING: newState={...newState, scarappedVehcielOffersLoading: true}; break;
        case SCRAPPEDVEHICLEOFFERSLOADED: newState={...newState, scarappedVehcielOffersLoading: false, scrappedVehicleOffers: action?.payload?.offers, scrappedVehicleAuctionExpired: action?.payload?.isExpired, claimHasPreviousScrappedVehicleAuction: action?.payload?.hasPreviousAuctions}; break;
        case GENERATEQUTITTANCELOADING: newState={...newState, generateQuittanceLoading: true}; break;
        case GENERATEQUTITTANCELOADED: newState={...newState, generateQuittanceLoading: false}; break;
        case QUTITTANCEDELETING: newState={...newState, quittanceDeleting: true}; break;
    }
    return newState;
};
