import {combineReducers} from 'redux';
import { AddPriceFromClientReducer } from './app/screens/add-price-from-client/AddPriceFromClient.reducer';
import { AuthReducer } from './app/screens/Auth/Auth.reducer';
import { BoardMonitorReducer } from './app/screens/board-monitor/BoardMonitor.reducer';
import { ClaimsReducer } from './app/screens/claims/Claims.reducer';
import { CreateNewOrderReducer } from './app/screens/create-new-order/CreateNewOrder.reducer';
import { EditClaimDetailsReducer } from './app/screens/edit-claim-details/EditClaimDetails.reducer';
import { EditOrderReducer } from './app/screens/edit-order/EditOrder.reducer';
import { EditProfileReducer } from './app/screens/edit-profile/EditProfile.reducer';
import { MainReducer } from './app/screens/main/Main.reducer';
import { OrderOptionsReducer } from './app/screens/order-options/OrderOptions.reducer';
import { SearchOffersReducer } from './app/screens/search-offers/SearchOffers.reducer';
import { LossAdjustingReducer } from './app/screens/edit-order/loss-adjusting/LossAdjusting.reducer';
import { ReportsReducer } from './app/screens/reports/Reports.reducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    main: MainReducer,
    claims:ClaimsReducer,
    board_monitor: BoardMonitorReducer,
    searchData: SearchOffersReducer,
    createOrder:CreateNewOrderReducer,
    editOrder: EditOrderReducer,
    addPriceromClient: AddPriceFromClientReducer,
    orderoption: OrderOptionsReducer,
    editProfile: EditProfileReducer,
    editClaimDetails: EditClaimDetailsReducer,
    lossAdjusting: LossAdjustingReducer,
    reports: ReportsReducer,
});

export default rootReducer;

