export const LOADING = 'Auth_LOADING';
export const API_SUCCESS = 'Auth_API_SUCCESS';
export const API_FAILURE = 'Auth_API_FAILURE';
export const LOGGING = 'Auth_LOGGING';
export const LOGIN_FAILED = 'Auth_LOGIN_FAILED';
export const API_DONE = 'Auth_API_DONE';
export const START = 'START';
export const SMSSENDING = 'SMSSENDING';
export const SMSSENT = 'SMSSENT';
export const TOKENREFRESHED = 'TOKENREFRESHED';

