import {RouterGuard} from 'react-router-guard';
import React from "react";
import getconfig from "./config";
import {Row, Col} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const CustomLoading = () => {
    return <Row justify={'center'} style={{marginTop: 120}}>
        <Col>
            <LoadingOutlined style={{fontSize: '26px'}}/>
        </Col>
    </Row>;
};
export default () => {
    return <RouterGuard config={getconfig} loading={CustomLoading()}/>
}
