import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import monitorReducersEnhancer from './app/enhancers/monitorReducers';
import rootReducer from './rootReducer';

export type AppState = ReturnType<typeof rootReducer>;

export default function configureAppStore(preloadedState?: any) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: [
            /*loggerMiddleware, */ ...getDefaultMiddleware({
                serializableCheck: false
            })
        ],
        preloadedState,
        enhancers: [monitorReducersEnhancer]
    });
    return store;
}
