export const initialState = {
    priceOffersLoading: false,
    fixingFeesLoading: false,
    purchaseOrdersLoading: false,
    claimLoading: false,
    fixingFeesSaving: false,
    fixingFeesLogsLoading: false,
    reportArrivalTimeSaving: false,
    partsPriceSaving: false,
};
