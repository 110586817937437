import { lazy } from 'react-router-guard';
import { getRoutePrefix } from '../services/helpers';
import { mustAuth, mustUnAuth } from './guards'


export default [
    {
        path: getRoutePrefix('/login'),
        exact: true,
        component: lazy(() => import('../screens/Auth/Auth.ui')),
        canActivate: [mustUnAuth],
    },

    {
        path: getRoutePrefix('/'),
        component: lazy(() => import('../screens/main/Main.ui')),
        routes: [
            {
                path: getRoutePrefix('/change-password'),
                exact: true,
                component: lazy(() => import('../screens/change-password/ChangePasswordPage.ui')),
            },
            {
                path: getRoutePrefix('/claims'),
                exact: true,
                component: lazy(() => import('../screens/claims/Claims.ui')),
            },
            {
                path: getRoutePrefix('/archive/claims'),
                exact: true,
                component: lazy(() => import('../screens/claims/ArchivedClaims.ui')),
            },
            {
                path: getRoutePrefix('/reports/compare-garage-prices'),
                exact: true,
                component: lazy(() => import('../screens/reports/ui/CompareGaragePricesReport.ui')),
            },
            {
                path: getRoutePrefix('/reports/statistics'),
                exact: true,
                component: lazy(() => import('../screens/reports/ui/Statistics.ui'))
            },
            {
                path: getRoutePrefix('/reports/parts-supply-evaluations'),
                exact: true,
                component: lazy(() => import('../screens/reports/ui/PartsSupplyEvaluationsReport.ui')),
            },
            {
                path: getRoutePrefix('/reports/commitments'),
                exact: true,
                component: lazy(() => import('../screens/reports/ui/Commitments.ui'))
            },
            {
                path: getRoutePrefix('/reports/sellers-evaluations'),
                exact: true,
                component: lazy(() => import('../screens/reports/ui/SellersEvaluations.ui')),
            },
            {
                path: getRoutePrefix('/reports/external-loss-adjustings'),
                exact: true,
                component: lazy(() => import('../screens/reports/ui/ExternalLossAdjustings')),
            },
            {
                path: getRoutePrefix('/claim/:claimId/edit'),
                exact: true,
                component: lazy(() => import('../screens/edit-order/EditOrder.ui')),
            },
            {
                path: getRoutePrefix('/archive/claim/:claimId/edit'),
                exact: true,
                component: lazy(() => import('../screens/edit-order/EditOrder.ui')),
            },
            {
                path: '/',
                redirect: getRoutePrefix('/claims'),
            }
        ],
        canActivate: [mustAuth],
    },


];

