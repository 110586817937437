import {Action, Reducer} from 'redux';
import { POLICIESLOADING, POLICIESLOADED, VEGHICLEMODELLOADING, VEGHICLEMODELLOADED, VEGHICLESUBMODELLOADED, VEGHICLESUBMODELLOADING, CLAIMSLISTSLOADING, CLAIMSLISTSLOADED, ADDCLAIMLOADING, ADDCLAIMLOADED, } from './CreateNewOrder.actions';



import {initialState} from './CreateNewOrder.state';

export const CreateNewOrderReducer: Reducer<any, Action> = ( state = initialState, action: any ) => {
    let newState = state
    switch (action.type) {
        case VEGHICLEMODELLOADING : newState={...newState,loading:true}; break;
        case VEGHICLEMODELLOADED : newState={...newState,loading:false, vehicleModels:action.payload}; break;
        case VEGHICLESUBMODELLOADING : newState={...newState,subModelLoading:true}; break;
        case VEGHICLESUBMODELLOADED : newState={...newState,subModelLoading:false, vehicleSubModels:action.payload}; break;
        case CLAIMSLISTSLOADING : newState={...newState,loading:true}; break;
        case CLAIMSLISTSLOADED : newState={...newState,loading:false, lists:action.payload}; break;
        case ADDCLAIMLOADING : newState={...newState,loading:true}; break;
        case ADDCLAIMLOADED : newState={...newState,loading:false, claim:action.payload}; break;
        case POLICIESLOADING : newState={...newState,loading:true}; break;
        case POLICIESLOADED : newState={...newState,loading:false, policies:action.payload}; break;

    }
    return newState;
};
