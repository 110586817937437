import {isValidToken, removeUser} from "../../services/authService";

export default (route: any) => {
    return new Promise((resolve, reject) => {
        // some my own notification set up with Redux
        const loggedIn = isValidToken();
        if (!loggedIn) {
            removeUser();
            reject(new Error('/login'));
        }
        resolve(true);
    });
};
