import {Action, Reducer} from 'redux';
import { MODELSLOADED, MODELSLOADING, SEARCHING, SEARCHED, CLAIMSPAGENUMBERLOADED } from './SearchOffers.actions';



import {initialState} from './SearchOffers.state';

export const SearchOffersReducer: Reducer<any, Action> = (state = initialState, action: any ) => {
    let newState = state;
    switch (action.type) {
        case MODELSLOADING : newState = {...newState, loading:true};
        break;

        case MODELSLOADED : newState = {...newState, loading:false, vehicleModels: action.payload}
        break;

        case SEARCHING : newState = {...newState, searchLoading:true}
        break;

        case SEARCHED : newState = {...newState, searchLoading: false, searchResult: action.payload}
        break;

        case CLAIMSPAGENUMBERLOADED: newState = {...newState, claimsLoading:false, claims_pages:action.payload};
        break;
    }
    return newState;
};
