export const API_SUCCESS = 'API_SUCCESS';
export const MODELSLOADING = "MODELSLOADING";
export const MODELSLOADED = "MODELSLOADED";

export const SEARCHING = "SEARCHING";
export const SEARCHED = "SEARCHED";
export const CLAIMSPAGENUMBERLOADED = "CLAIMSPAGENUMBERLOADED";

export const USER_SEARCHING = "USER_SEARCHING";
export const USER_SEARCHED = "USER_SEARCHED";

export const PRICE_OFFER_ADDING = 'PRICE_OFFER_ADDING';
export const PRICE_OFFER_ADDED = 'PRICE_OFFER_ADDED';