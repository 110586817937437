import {Action, Reducer} from 'redux';
import { LOADING, STATSLOADED, CLAIMSLOADING, CLAIMSLOADED, CLAIMSPAGENUMBERLOADED, EXTERNALCLAIMLOADED, FINANCIALCLAIMLOADED, CLAIMSLISTSLOADED, MODELSLOADING, MODELSLOADED } from './BoardMonitor.actions';



import {initialState} from './BoardMonitor.state';

export const BoardMonitorReducer: Reducer<any, Action> = (state = initialState, action: any ) => {
    let newState = state;
    switch (action.type) {
        case LOADING: newState = {...newState, loading: true};
            break;
        case STATSLOADED: newState = {...newState, loading:false, stats:action.payload};
            break;
        case CLAIMSLOADING: newState = {...newState, claimsLoading: true};
            break;
        case CLAIMSLOADED: newState = {...newState, claimsLoading:false, claims:action.payload};
            break;
        case CLAIMSPAGENUMBERLOADED: newState = {...newState, claimsLoading:false, claims_pages:action.payload};
            break;
        case EXTERNALCLAIMLOADED: newState = {...newState, externalClaim: action.payload };
            break;
        case FINANCIALCLAIMLOADED: newState = {...newState, financialClaims: action.payload };
            break;
        case CLAIMSLISTSLOADED :  newState={...newState,loading:false, lists:action.payload}; 
            break;
        case MODELSLOADING: newState={...newState,loading:true }
        break;
        case MODELSLOADED : newState = {...newState, loading:false, vehicleModels: action.payload}
        break;

    }   
    return newState;
};
