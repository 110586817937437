import {isValidToken} from "../../services/authService";

export default (route: any) => {
    return new Promise((resolve, reject) => {
        const loggedIn = isValidToken();
        if (loggedIn) {
            reject(new Error('/'));
        }
        resolve(true);
    });
};
